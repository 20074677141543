import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { View } from 'react-native';
import { Modal } from 'assets/components/modal';
import { InsuranceTestID } from './InsuranceTestID';
import { getText } from 'assets/localization/localization';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Form } from 'assets/layout';
import { useForm } from 'assets/form';
import { makeStyles } from 'assets/theme';
import { InsuranceHandler, InsuranceProps } from './InsuranceProps';
import { Text } from 'assets/components/text';

export const InsuranceModal = forwardRef<InsuranceHandler, InsuranceProps>(
  (
    { handleInsuranceSubmit = (value: string) => {} }: InsuranceProps,
    ref: ForwardedRef<InsuranceHandler>,
  ) => {
    const styles = useStyles();

    const methods = useForm({
      defaultValues: {
        changedInsurance: '',
      },
    });
    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnPress = () => {
      if (methods.getValues().changedInsurance === '') {
        setShowError(true);
        return;
      }
      handleInsuranceSubmit(methods.getValues().changedInsurance);
      handleDismissModal();
      methods.setValue('changedInsurance', '');
    };

    return (
      <Modal
        size="sm"
        title="Insurance"
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          testID: InsuranceTestID.cancelModalButton,
          logger: { id: InsuranceTestID.cancelModalButton },
        }}
        okButtonProps={{
          onPress: handleOnPress,
          testID: InsuranceTestID.okModalButton,
          logger: { id: InsuranceTestID.okModalButton },
        }}
      >
        <View>
          <Form methods={methods}>
            <Form.Row>
              <Form.Column>
                <RadioButtonGroupField
                  label={getText('changed-insurance')}
                  rules={{
                    required: getText('field-required'),
                  }}
                  name="changedInsurance"
                  values={[
                    {
                      text: getText('yes'),
                      value: 'yes',
                    },
                    {
                      text: getText('no'),
                      value: 'no',
                    },
                  ]}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                {showError && (
                  <Text style={styles.error}>{getText('field-required')}</Text>
                )}
              </Form.Column>
            </Form.Row>
          </Form>
        </View>
      </Modal>
    );
  },
);

const useStyles = makeStyles((theme) => ({
  error: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
    display: 'flex',
    alignItems: 'flex-start',
    textAlign: 'left',
    color: theme.palette.error[700],
  },
}));
