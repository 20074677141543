import { Button } from 'assets/components/button';
import { useForm } from 'assets/form';
import { Form, ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RefillGuestTestIDs } from '../../refill-guest/RefillGuestTestIDs';
import { useRefillMedicationsStore } from '../refill-store';
import { RefillStackParamList } from '../RefillNavigation';
import patientService from '../../../api/patient-service';
import { View } from 'react-native';
import { MedicalInfoFormFields } from '../../../components/medical-info/MedicalInfoFormFields';

export const OtherInformationModal: FunctionComponent<
  PropsWithChildren<OtherInformationNavigationProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { selectedPatientRecord: patientRecord, updateMedicationsData } =
    useRefillMedicationsStore();

  if (!patientRecord) throw Error('No Patient Record');

  const form = useForm({
    defaultValues: {
      allergies: patientRecord.allergies,
      medical_conditions: patientRecord.medical_conditions,
      prefers_easy_open_bottle_caps:
        patientRecord.prefers_easy_open_bottle_caps,
    },
  });

  const handleOnPressDone = async () => {
    if (!updateMedicationsData) return;

    const updatePatientRecord = {
      allergies: form.getValues().allergies,
      medical_conditions: form.getValues().medical_conditions,
      prefers_easy_open_bottle_caps:
        form.getValues().prefers_easy_open_bottle_caps === true,
    };

    updateMedicationsData({
      selectedPatientRecord: {
        ...patientRecord,
        ...updatePatientRecord,
      },
    });

    await patientService.updatePatientRecord(
      patientRecord.id,
      updatePatientRecord,
    );

    navigation.navigate('refill-review');
  };

  return (
    <ScreenContainer style={{ marginTop: theme.getSpacing(2) }}>
      <Form methods={form}>
        <MedicalInfoFormFields />
        <Form.Actions>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              onPress={form.handleSubmit(handleOnPressDone)}
              testID={RefillGuestTestIDs.refillGuestFindStoreNext}
              logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
            >
              {getText('review')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

type OtherInformationNavigationProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-other-information'
>;
const useStyles = makeStyles(() => ({
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
}));
