import { FunctionComponent, useState, useCallback } from 'react';
import { Platform, View } from 'react-native';
import { v4 } from 'uuid';
import {
  GiftedChat,
  GiftedChatProps,
  IMessage,
  ActionsProps,
  BubbleProps,
  InputToolbar,
  Composer,
} from 'react-native-gifted-chat';
import { MessageSendIcon, MicIcon, PlusSquareIcon } from 'assets/icons';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { notImplementedAlert } from 'assets/utils/alert';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatDateTime,
  timeAugmenter,
} from '../../common/datetime-utils';
import { IconButton } from 'assets/components/icon-button';
import { getText } from 'assets/localization/localization';
import { onType } from './InitializeSockets';
import WritingBar from '../../../../pharmacy/modules/screens/messages/components/WritingBar';
import { MESSAGE_LIMIT } from '../../../../pharmacy/modules/screens/messages/data';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { Icon } from 'assets/components/icon';
import React from 'react';
import { ChatBoxActionBottomSheet } from './ChatBoxActionsBottomSheet';
import { ChatBoxActionsModal } from './ChatBoxActionsModal';
import { DirectMessagePatientDto } from '@digitalpharmacist/unified-communications-service-client-axios';
import { ParamListBase, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useMessagesState } from './messages-store';
import { useUserState } from '../../store/user-store';

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const { user } = useUserState();
  const [text, setText] = useState<string>('');
  const styles = useStyles();
  const theme = useTheme();
  const minuteThreshold = 5;
  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [showActions, setShowActions] = useState<boolean>(false);
  const navigation = useNavigation<StackNavigationProp<ParamListBase>>();
  const { socket, typingMember } = useMessagesState();

  const openActions = () => {
    sheetRef.current?.present();
    setShowActions(true);
  };

  const closeAction = () => {
    sheetRef.current?.dismiss();
    setShowActions(false);
    navigation.navigate('app', { screen: 'messages' });
  };

  const TimeStampComponent = (props: {
    args: Readonly<BubbleProps<IMessage>>;
  }) => {
    if (
      props.args.currentMessage &&
      (!props.args.nextMessage?.text ||
        timeAugmenter(props.args.nextMessage.createdAt, 'm', -minuteThreshold) >
          props.args.currentMessage.createdAt)
    ) {
      return (
        <Text style={styles.subText}>
          Sent{' '}
          {formatDateTime(
            props.args.currentMessage.createdAt.toLocaleString(),
            DEFAULT_DATE_TIME_FORMAT,
            DEFAULT_TIME_FORMAT,
          )}
        </Text>
      );
    } else {
      return null;
    }
  };

  const NameComponent = (props: { args: Readonly<BubbleProps<IMessage>> }) => {
    if (
      props.args.currentMessage &&
      (!props.args.previousMessage?.text ||
        timeAugmenter(
          props.args.previousMessage.createdAt,
          'm',
          minuteThreshold,
        ) < props.args.currentMessage.createdAt)
    ) {
      return (
        <Text style={styles.subText}>
          {props.args.currentMessage.user.name}
        </Text>
      );
    } else {
      return null;
    }
  };

  const renderBubble = useCallback(
    (args: Readonly<BubbleProps<IMessage>>) => {
      const isLeft = args.position === 'left';
      const isOwner = args.user?._id === args.currentMessage?.user._id;
      return (
        <View
          style={[
            { flex: 1, alignItems: 'flex-end' },
            isLeft && { alignItems: 'flex-start' },
          ]}
        >
          <NameComponent args={args} />
          <View
            style={[
              styles.bubbleContainer,
              isLeft && { marginLeft: 0, marginRight: 60 },
              isOwner && { backgroundColor: theme.palette.primary[200] },
            ]}
          >
            <Text style={[styles.bubbleText]}>{args.currentMessage?.text}</Text>
          </View>
          <TimeStampComponent args={args} />
        </View>
      );
    },
    [props.messages],
  );

  const renderActions = useCallback((props: Readonly<ActionsProps>) => {
    return (
      <TouchableOpacity style={styles.actions} onPress={openActions}>
        <Icon
          icon={PlusSquareIcon}
          color={theme.palette.gray[500]}
          size={28}
        ></Icon>
      </TouchableOpacity>
    );
  }, []);

  const renderComposer = () => {
    return (
      <View style={styles.composerContainer}>
        <Composer
          placeholder={`${getText('secure-message')}...`}
          text={text}
          onTextChanged={onInputTextChanged}
          keyboardAppearance="default"
          textInputStyle={styles.composerStyle}
          textInputProps={{ maxLength: MESSAGE_LIMIT }}
        />

        {text ? (
          <IconButton
            icon={MessageSendIcon}
            logger={{ id: 'send-message' }}
            style={styles.composerIcons}
            onPress={handleMessageSend}
          />
        ) : (
          <IconButton
            icon={MicIcon}
            logger={{ id: 'mic-press' }}
            style={styles.composerIcons}
            onPress={notImplementedAlert}
          />
        )}
      </View>
    );
  };

  const handleMessageSend = useCallback(() => {
    const user = props.user;
    const textToSend = text.trim();
    if (text && props.onSend && user) {
      const newMessage = {
        _id:
          (props.messageIdGenerator && props.messageIdGenerator()) ??
          `${user._id} ${textToSend}`,
        text: textToSend,
        user: user,
        createdAt: new Date(),
      };
      props.onSend([newMessage]);

      setText('');
    }
  }, [text]);

  const renderInputToolbar = () => {
    return (
      <InputToolbar
        renderActions={renderActions}
        renderComposer={renderComposer}
        containerStyle={styles.inputToolbar}
      />
    );
  };

  const onInputTextChanged = useCallback(
    (text: string) => {
      if (props.conversation && socket)
        onType(props.conversation.conversation_id, user, socket);
      setText(text);
    },
    [setText, onType, props.conversation, socket],
  );

  return (
    <>
      <GiftedChat
        {...props}
        messages={props.messages}
        text={text}
        renderBubble={renderBubble} // *MESSAGES NOT INCLUDING AVATARS*
        renderAvatar={() => null}
        showAvatarForEveryMessage={true}
        maxInputLength={MESSAGE_LIMIT}
        renderInputToolbar={renderInputToolbar}
        onInputTextChanged={onInputTextChanged} // *MIGHT BE NEEDED TO UPDATE STATUS "IS TYPING" (IN LOOP UPDATE TIMER NUMBER FOR THIS VALUE)*
        renderFooter={() => {
          if (props.conversation) {
            return (
              <WritingBar
                typingMember={typingMember}
                conversationId={props.conversation.conversation_id}
              />
            );
          }
        }}
      />
      {props.conversation ? (
        Platform.OS === 'web' ? (
          <ChatBoxActionsModal
            show={showActions}
            setShow={setShowActions}
            onClose={closeAction}
            conversation={props.conversation}
          />
        ) : (
          <ChatBoxActionBottomSheet
            sheetRef={sheetRef}
            onClose={closeAction}
            conversation={props.conversation}
          />
        )
      ) : null}
    </>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  conversation?: DirectMessagePatientDto;
}

ChatBox.defaultProps = {
  alwaysShowSend: true,
  showUserAvatar: false,
  scrollToBottom: true,
  messageIdGenerator: () => v4(),
  // isTyping: true,
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
    padding: 10,
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubbleText: {
    ...theme.fonts.regular,
    fontSize: 14,
    overflowWrap: 'break-word',
  },
  subText: {
    color: theme.palette.gray[500],
    fontSize: 11,
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },

  composerStyle: {
    color: theme.palette.gray[500],
    width: '100%',
    flex: 1,
    paddingTop: theme.getSpacing(1),
  },

  composerContainer: {
    height: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.palette.gray[400],
    marginBottom: theme.getSpacing(0.5),
    marginRight: theme.getSpacing(3),
    alignItems: 'center',
  },

  inputToolbar: {
    borderTopWidth: 0,
    marginBottom: getSpacing(1),
  },

  composerIcons: {
    marginBottom: 4,
    marginRight: 4,
  },

  sendIcon: {
    marginBottom: theme.getSpacing(2),
  },

  actions: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
    marginBottom: 12,
  },
}));
